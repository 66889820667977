import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
    dsn:
        SENTRY_DSN ||
        'https://a5f4e190bdd94319a3712f584023e964@o1308555.ingest.sentry.io/6554054',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
});
